div.dt-button-collection > :last-child {
    display: flex !important;
    flex-direction: column;
}

.card-profile {
    padding-top: 80px;
}

div.ql-editor {
    min-height: 10rem;
}

span.help-block,
div.help-block {
    font-size: 10px;
}

.invoice-add .invoice-total-wrapper-longer {
    max-width: unset !important;
}

.invoice-add .invoice-total-wrapper .invoice-total-item .invoice-total-title {
    min-width: 40%;
}

@media (min-width: 1200px) {
    table.dataTable > tbody > tr.child ul.dtr-details > li {
        display: flex;
        flex-direction: column;
    }

    table.dataTable > tbody > tr.child ul.dtr-details {
        text-align: center;
        justify-content: space-evenly;
        display: flex !important;
    }
}

.invoice-add .invoice-preview-card .invoice-edit-input,
.invoice-add .invoice-preview-card .invoice-edit-input-group,
.invoice-edit .invoice-preview-card .invoice-edit-input,
.invoice-edit .invoice-preview-card .invoice-edit-input-group {
    max-width: 16rem !important;
}

.form-select-sm {
    padding-bottom: 0.388rem;
    padding-top: 0.388rem;
}

html .navbar-floating.footer-static .app-content .content-area-wrapper, html .navbar-floating.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 90 - 11.1rem);
}

.dataTable td:not(.control, .dt-checkboxes-cell) {
    padding: .3rem !important;
    font-size: 12px !important;
}


.dataTable th:not(.control, .dt-checkboxes-cell) {
    padding: .6rem .3rem !important;
    font-size: 12px !important;
}

.dt-action-buttons .dropdown-menu {
    transform: none !important;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 60% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}